var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-10 px-5 pb-10 bg-secondary min-h-screen",staticStyle:{"height":"700px"}},[_c('div',{staticClass:"flex flex-col items-center"},[_c('img',{staticClass:"w-72",attrs:{"src":"/the-one-stellenbosch/images/logo.svg"}}),_c('p',{staticClass:"mt-10 text-xl font-semibold text-white"},[_vm._v(" Welcome to "+_vm._s(_vm.title)+" "+_vm._s(_vm.offer)+" Offer ")]),_c('p',{staticClass:"mt-5 text-white"},[_vm._v(" Submit your details and proceed to the next step. ")]),_c('form',{staticClass:"mt-10 p-5 bg-gray-200 rounded pb-50",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"flex -mx-3"},[_c('div',{staticClass:"w-1/2 px-3"},[_c('label',{staticClass:"lb-icon-text-label",attrs:{"for":"register-firstName"}},[_vm._v("First name")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"lb-icon-text-icon"},[_c('NameIcon')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "firstName", $event.target.value)}}})]),_c('span',{staticClass:"text-xs text-red-600",class:{
              '': _vm.$v.form.firstName.$error,
              invisible: !_vm.$v.form.firstName.$error,
            }},[_vm._v(" Required")])]),_c('div',{staticClass:"w-1/2 px-3"},[_c('label',{staticClass:"lb-icon-text-label",attrs:{"for":"register-lastName"}},[_vm._v("Last name")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"lb-icon-text-icon"},[_c('NameIcon')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "lastName", $event.target.value)}}})]),_c('span',{staticClass:"text-xs text-red-600",class:{
              '': _vm.$v.form.lastName.$error,
              invisible: !_vm.$v.form.lastName.$error,
            }},[_vm._v(" Required")])])]),_c('div',{staticClass:"flex -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('label',{staticClass:"lb-icon-text-label",attrs:{"for":"register-email"}},[_vm._v("Email")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"lb-icon-text-icon"},[_c('EmailIcon')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary",attrs:{"type":"email","placeholder":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('span',{staticClass:"text-xs text-red-600",class:{
              '': _vm.$v.form.email.$error,
              invisible: !_vm.$v.form.email.$error,
            }},[_vm._v(" Please enter a valid email address")])])]),_c('div',{staticClass:"flex -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-2"},[_c('label',{staticClass:"lb-icon-text-label",attrs:{"for":""}},[_vm._v("Contact Number")]),_c('div',{staticClass:"flex"},[_c('VuePhoneNumberInput',{staticClass:"w-full outline-none focus:border-primary",attrs:{"size":"md","color":"#aa9461","default-country-code":"ZA"},on:{"update":_vm.updatePhoneNumber},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}})],1),_c('span',{staticClass:"text-xs text-red-600",class:{
              '': _vm.$v.form.contactNumber.$error,
              invisible: !_vm.$v.form.contactNumber.$error,
            }},[_vm._v(" Required")])])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('input',{staticClass:"lb-primary-button w-full",attrs:{"type":"submit","value":"proceed to offer"}})])
}]

export { render, staticRenderFns }