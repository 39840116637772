<template>
  <div class="pt-10 px-5 pb-10 bg-secondary min-h-screen" style="height: 700px">
    <div class="flex flex-col items-center">
      <img class="w-72" src="/the-one-stellenbosch/images/logo.svg" />
      <p class="mt-10 text-xl font-semibold text-white">
        Welcome to {{ title }} {{ offer }} Offer
      </p>
      <p class="mt-5 text-white">
        Submit your details and proceed to the next step.
      </p>

      <form
          @submit.prevent="submitForm"
          class="mt-10 p-5 bg-gray-200 rounded pb-50"
      >
        <div class="flex -mx-3">
          <div class="w-1/2 px-3">
            <label for="register-firstName" class="lb-icon-text-label"
            >First name</label
            >
            <div class="flex">
              <div class="lb-icon-text-icon">
                <NameIcon />
              </div>
              <input
                  v-model="form.firstName"
                  type="text"
                  class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                  placeholder=""
              />
            </div>
            <span
                class="text-xs text-red-600"
                :class="{
                '': $v.form.firstName.$error,
                invisible: !$v.form.firstName.$error,
              }"
            >
              Required</span
            >
          </div>

          <div class="w-1/2 px-3">
            <label for="register-lastName" class="lb-icon-text-label"
            >Last name</label
            >
            <div class="flex">
              <div class="lb-icon-text-icon">
                <NameIcon />
              </div>
              <input
                  v-model="form.lastName"
                  type="text"
                  class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                  placeholder=""
              />
            </div>
            <span
                class="text-xs text-red-600"
                :class="{
                '': $v.form.lastName.$error,
                invisible: !$v.form.lastName.$error,
              }"
            >
              Required</span
            >
          </div>
        </div>

        <div class="flex -mx-3">
          <div class="w-full px-3">
            <label for="register-email" class="lb-icon-text-label">Email</label>
            <div class="flex">
              <div class="lb-icon-text-icon">
                <EmailIcon />
              </div>
              <input
                  v-model="form.email"
                  type="email"
                  class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                  placeholder=""
              />
            </div>
            <span
                class="text-xs text-red-600"
                :class="{
                '': $v.form.email.$error,
                invisible: !$v.form.email.$error,
              }"
            >
              Please enter a valid email address</span
            >
          </div>
        </div>

        <div class="flex -mx-3">
          <div class="w-full px-3 mb-2">
            <label for="" class="lb-icon-text-label">Contact Number</label>
            <div class="flex">
              <VuePhoneNumberInput
                  v-model="form.contactNumber"
                  @update="updatePhoneNumber"
                  class="w-full outline-none focus:border-primary"
                  size="md"
                  color="#aa9461"
                  default-country-code="ZA"
              />
            </div>
            <span
                class="text-xs text-red-600"
                :class="{
                '': $v.form.contactNumber.$error,
                invisible: !$v.form.contactNumber.$error,
              }"
            >
              Required</span
            >
          </div>
        </div>

        <div class="flex items-center">
          <input
              class="lb-primary-button w-full"
              type="submit"
              value="proceed to offer"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import { formsCollection } from "../../firebase.js";

import { APP_TITLE, APP_ONE_DAY_ONLY } from "../../Constants.js";

import { required, email } from "vuelidate/lib/validators";
import EmailIcon from "vue-material-design-icons/At";
import NameIcon from "vue-material-design-icons/Account";

export default {
  name: "RedirectSurvey",
  components: {
    VuePhoneNumberInput,
    EmailIcon,
    NameIcon,
  },
  data: () => ({
    title: APP_TITLE,
    offer: null,
    form: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      e164ContactNumber: "",
      formatNationalContactNumber: "",
    },
  }),
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      contactNumber: { required },
    },
  },
  methods: {
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const timeStamp = firebase.firestore.Timestamp.now();
      var form = String;
      var code = String;
      var redirectUnit = String;

      const ref = this.$route.params.ref;
      if (ref === "onedayonly") {
        form = "OneDayOnly Special";
        code = "onedayonly";
        redirectUnit = APP_ONE_DAY_ONLY;
      }

      const formData = {
        form: form,
        code: code,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        contactNumber: this.form.contactNumber,
        formatNationalContactNumber: this.form.formatNationalContactNumber,
        e164ContactNumber: this.form.e164ContactNumber,
        timestamp: timeStamp,
      };

      formsCollection
          .add(formData)
          .then(() => {
            this.$router.push({
              name: "onedayonlyunit",
              params: { name: redirectUnit },
            });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              text: error.message,
            });
          });
    },
    updatePhoneNumber(e) {
      this.form.e164ContactNumber = e.e164;
      this.form.formatNationalContactNumber = e.formatNational;
    },
  },
  mounted() {
    const ref = this.$route.params.ref;

    if (ref === "onedayonly") {
      this.offer = "OneDayOnly";
    } else {
      this.$router.push({ name: "not-found" });
    }
  },
};
</script>